import { useEffect, useState } from "react"

import { useFetchCurrentSubscription } from "src/data/billing/queries/billingQueries"
import { TUserBillingInfo } from "src/data/billing/types/billingTypes"
import { useFetchShippingDestinations } from "src/data/ecommerce/ecommerceQueries"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { ErrorService } from "src/utils/ErrorService"
import { fetchGeoLocationData } from "src/utils/fetchGeoLocationData"
import { logger } from "src/utils/logger"

/**
 * Hook to determine the currency code for a customer based on various factors.
 * The shipping destinations api is used to get the currency code for the countries
 * that we ship to.
 *
 * The currency is determined by first checking the user's existing subscription
 * currency, then falling back to the customer's billing country currency,
 * followed by their geolocation-based currency, and finally defaulting to USD
 * if none of the above are available.
 */
export function useCustomerCurrency({
  userBillingInfo,
  loading,
}: {
  userBillingInfo: TUserBillingInfo | null | undefined
  loading: boolean
}) {
  const { user_id } = useGetUser()

  const fetchSubscription = useFetchCurrentSubscription({
    userId: user_id,
  })
  const fetchShippingDestinations = useFetchShippingDestinations()

  const subscription = fetchSubscription.data
  const shippingDestinations = fetchShippingDestinations.data

  const [currencyCode, setCurrencyCode] = useState(subscription?.currency_code)

  const isLoading =
    fetchSubscription.isLoading || fetchShippingDestinations.isLoading

  // Determine currency
  useEffect(() => {
    // We only determine the currency here with this logic once we have fetched
    // the current subscription (if any)
    if (isLoading || !shippingDestinations) {
      return
    }

    // Select currency of existing subscription if the user has one
    if (subscription) {
      setCurrencyCode(subscription.currency_code)
      return
    }

    // Otherwise try to select currency based on the billing country
    const billingCountry = userBillingInfo?.billing_address?.country

    if (billingCountry) {
      const currencyCode = shippingDestinations.find(
        (destination) => destination.country_code === billingCountry
      )?.currency_code

      if (currencyCode) {
        setCurrencyCode(currencyCode)
        return
      }
    }

    // If none of the above is true, select based on geolocation
    fetchGeoLocationData()
      .then((d) => {
        const match = shippingDestinations.find(
          (destination) => destination.country_code === d.countryCode
        )

        if (match) {
          setCurrencyCode(match.currency_code)
        } else {
          logger.info(
            "No matching shipping desitnation found, fallbacking to USD"
          )
          setCurrencyCode("USD")
        }
      })
      .catch((error) => {
        logger.error(error)
        ErrorService.captureException(error, {
          context: {
            state: {
              billingCountry,
              subscription,
              shippingDestinations,
            },
          },
        })
        logger.info("Fallbacking to USD")
        setCurrencyCode("USD")
      })
  }, [userBillingInfo, loading, subscription, isLoading, shippingDestinations])

  return currencyCode
}
