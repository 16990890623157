import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { API_DEFAULT } from "src/constants/minutApi"
import {
  TAvailablePlan,
  TUserBillingInfo,
} from "src/data/billing/types/billingTypes"
import { subscriptionKeys } from "src/data/subscription/queries/subscriptionKeys"
import {
  TPatchSubscriptionBody,
  TPatchSubscriptionError400,
  TPatchSubscriptionResponse,
  TPostCreateSubscriptionEstimateResponse,
  TPostSubscriptionBody,
  TPostSubscriptionError400,
  TPostSubscriptionEstimateBody,
  TPostSubscriptionResponse,
  TPostUpdateSubscriptionEstimate,
  TPostUpdateSubscriptionEstimateBody,
} from "src/data/subscription/types/subscriptionTypes"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

type TCountryCode = TPostSubscriptionEstimateBody["billing_country"]

// https://api.staging.minut.com/latest/docs/internal#post-/subscriptions/estimate/create_subscription
async function postCreateSubscriptionEstimate(
  body: TPostSubscriptionEstimateBody
) {
  const response =
    await minutApiHttpClient.post<TPostCreateSubscriptionEstimateResponse>(
      `${API_DEFAULT}/subscriptions/estimate/create_subscription`,
      body
    )

  return response.data
}

export function useFetchUpdateSubscriptionEstimate({
  orgId,
  body,
  options,
}: {
  orgId: string
  body: TPostUpdateSubscriptionEstimateBody
  options?: UseQueryOptions<
    TPostUpdateSubscriptionEstimate,
    AxiosError,
    TPostUpdateSubscriptionEstimate,
    ReturnType<typeof subscriptionKeys.subscriptionUpdateEstimate>
  >
}) {
  // https://api.staging.minut.com/latest/docs/internal#post-/subscriptions/estimate/update_subscription
  async function postUpdateSubscriptionEstimate(
    body: TPostUpdateSubscriptionEstimateBody
  ) {
    const response =
      await minutApiHttpClient.post<TPostUpdateSubscriptionEstimate>(
        `${API_DEFAULT}/subscriptions/estimate/update_subscription`,
        body
      )

    return response.data
  }

  return useQuery({
    ...options,
    queryKey: subscriptionKeys.subscriptionUpdateEstimate(orgId, body),
    queryFn: () => postUpdateSubscriptionEstimate(body),
  })
}

export function useFetchCreateSubscriptionEstimates({
  availablePlans,
  quantity,
  userBillingInfo,
  discountCode,
  options,
}: {
  availablePlans: TAvailablePlan[]
  quantity: number
  userBillingInfo: TUserBillingInfo | undefined
  discountCode?: string
  options?: UseQueryOptions<
    TPostCreateSubscriptionEstimateResponse[],
    AxiosError,
    TPostCreateSubscriptionEstimateResponse[],
    ReturnType<typeof subscriptionKeys.subscriptionEstimates>
  >
}) {
  const couponIds = discountCode ? [discountCode] : undefined

  return useQuery({
    queryKey: subscriptionKeys.subscriptionEstimates(availablePlans),
    queryFn: () =>
      Promise.all(
        availablePlans.map((plan) =>
          postCreateSubscriptionEstimate({
            plan_id: plan?.plan_id,
            plan_quantity: quantity,
            billing_country:
              (userBillingInfo?.billing_address?.country as TCountryCode) ||
              "US",
            vat_number: userBillingInfo?.vat_number,
            coupon_ids: couponIds,
          })
        )
      ),
    ...options,
  })
}

export function usePostSubscription() {
  async function postSubscription(body: TPostSubscriptionBody) {
    const response = await minutApiHttpClient.post<TPostSubscriptionResponse>(
      `${API_DEFAULT}/subscriptions`,
      body
    )

    return response.data
  }

  return useMutation<
    TPostSubscriptionResponse,
    AxiosError<TPostSubscriptionError400>,
    TPostSubscriptionBody
  >({
    mutationFn: postSubscription,
  })
}

export function usePatchSubscription() {
  // https://api.staging.minut.com/latest/docs/internal#patch-/subscriptions/-subscription_id-
  async function patchSubscription({
    subscriptionId,
    body,
  }: {
    subscriptionId: string
    body: TPatchSubscriptionBody
  }) {
    const response = await minutApiHttpClient.patch<
      TPatchSubscriptionResponse,
      TPatchSubscriptionError400
    >(`${API_DEFAULT}/subscriptions/${subscriptionId}`, body)

    return response.data
  }

  return useMutation({
    mutationFn: patchSubscription,
  })
}
