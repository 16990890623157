import { isDev, isProdEnv, isStagingEnv } from "src/constants/env"
import { TUserBillingInfoBillingAddress } from "src/data/billing/types/billingTypes"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { useFlags } from "src/hooks/useFlags"

export function useMinutEmployee() {
  const user = useGetUser()
  const flags = useFlags()
  const eligible = user.email.endsWith("@minut.com") && flags.minutEmployee
  const coupon = (() => {
    if (!eligible) return ""
    if (isDev || isStagingEnv) return "100-PERCENT"
    if (isProdEnv) return "" // we can add a prod coupon code here
    return ""
  })()

  const testBillingAddress: TUserBillingInfoBillingAddress | null = eligible
    ? {
        city: "Miami",
        company: "Miami Company",
        country: "US",
        email: user.email,
        first_name: user.fullname,
        last_name: "Tester",
        line1: "Some Street",
        line2: "",
        phone: user.phone_number ?? "",
        // state: "",
        state_code: "FL",
        zip: "33009",
      }
    : null

  return { coupon, eligible, testBillingAddress }
}
